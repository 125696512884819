import {TimeRecurs} from '@/helper/TimeRecurs';
import moment, {Moment} from 'moment';
import i18n from '@/i18n';

export function showTimes(time: TimeDuration) {
  const startMoment = moment(time.hour + ':' + time.minute, 'HH:mm');
  return startMoment.format(
      i18n.t('GENERAL.DATE_FORMATS.TIME').toString()) +
    i18n.t('GENERAL.CLOCK').toString() +
    ' - ' +
    startMoment.clone().add(time.duration, 'milliseconds').format(i18n.t('GENERAL.DATE_FORMATS.TIME').toString());
}

export class TimeDuration {
  public hour: number;
  public minute: number;
  public duration: number;
  public occurrences?: TimeRecurs | null = null;

  constructor(hour: number, minute: number, duration: number, recurs: TimeRecurs | null) {
    this.hour = hour;
    this.minute = minute;
    this.duration = duration;
    this.occurrences = recurs;
  }

  public static fromAny(dateStart: string | Date | Moment, dateEnd: string | Date | Moment) {
    const start = moment(dateStart);
    const end = moment(dateEnd);
    return new TimeDuration(start.hour(), start.minute(), end.diff(start), null);
  }
}
