



















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import WorkSession from '@/models/WorkSession';
import i18n from '@/i18n';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import ActtComponent from '@/components/actt/Actt.component.vue';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import SignatureComponent from '@/components/shared/Signature.component.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {TabItem} from '@/interfaces/TabItem';
import Task from '@/models/Task';
import WorkSessionManageComponent from '@/components/work-session/WorkSessionManageComponent.vue';
import SidarbarDetailsComponent from './SidarbarDetails.component.vue';
import TrackingBarDetailsComponent from '@/components/time-tracking/TrackingBarDetails.component.vue';
import CommentTabComponent from '@/components/shared/SideCard/CommentTab.component.vue';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
const JobStore = namespace('job');
const CustomerStore = namespace('customer');
const UserStore = namespace('user');

/**
 * This component represents the content of the side card used to display session details in the time tracking overview.
 */
@Component({
  components: {
    TrackingBarDetailsComponent,
    RJTabs,
    SidarbarDetailsComponent,
    RJTextField,
    ActtComponent,
    SignatureComponent,
    UserInitialsComponent,
    WorkSessionManageComponent,
    CommentTabComponent,
  },
})
export default class TimeTrackingSidebarContentComponent extends Vue {

  @JobStore.Action('loadWorkSessionAction')
  public loadWorkSessionAction!: (workSessionId: string) => Promise<WorkSession>;
  @CustomerStore.Action('loadCustomerAction')
  public loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Action('loadLocationAction')
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Getter('customers')
  private _customers!: Customer[];
  @UserStore.Action('loadUserAction')
  private loadUserAction!: (userId: string) => Promise<User>;
  /**
   * The currently selected work session. May be null while a new work session is being loaded.
   */
  @Prop({default: null})
  public selectedWorksession!: WorkSession | null;
  private selectedCustomer: Customer | null = null;
  private selectedLocation: Location | null = null;
  private workSessionAuthor: User | null = null;
  public workSessions: WorkSession[] = [];
  private tabsModel: number = 0;
  private expand: number[] = [];
  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};

  // functions
  constructor() {
    super();
  }

  /**
   * is True if the worksession has signatures
   */
  public get showSignatureTab(): boolean {
    return !!this.selectedWorksession && !!(this.selectedWorksession?.employeeSign || this.selectedWorksession?.customerSign);
  }

  @Watch('selectedWorksession')
  private onWorkSessionChanged() {
    if (this.selectedWorksession) {
      this.selectedCustomer = null;
      this.loadLocationAction({
        locationId: this.selectedWorksession.locationId,
        shouldBeStored: false,
      }).then((value) => {
        this.selectedLocation = value;
      });
      if (this.selectedWorksession.vcAuthorId) {
        this.loadUserAction(this.selectedWorksession.vcAuthorId).then((value) => {
          this.workSessionAuthor = value;
        });
      }
      this.loadCustomerAction(this.selectedWorksession.customerId).then((value) => {
        this.selectedCustomer = value;
      });
    }
  }

  get locale(): string {
    return i18n.locale;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'employee',
        text: this.$t('GENERAL.EMPLOYEE').toString(),
        available: true,
      }, {
        key: 'areas',
        text: this.$t('GENERAL.AREAS').toString(),
        available: true,
      }, {
        key: 'comments',
        text: this.$t('TIMETRACKING.HEADER.COMMENTS').toString(),
        available: this.selectedWorksession?.hasComments,
      }, {
        key: 'signatures',
        text: this.$t('GENERAL.SIGNATURES').toString(),
        available: this.showSignatureTab,
      },
    ];
  }

  private onClick(key: number) {
    const index = this.expand.findIndex((value) => value === key);
    if (index !== -1) {
      this.expand.splice(index, 1);
    } else {
      this.expand.push(key);
    }
  }

  public isChecked(task: Task): boolean {
    if (this.selectedWorksession?.checkedTasks) {
      return this.selectedWorksession.checkedTasks.map((item) => item.id).includes(task.id);
    }
    return false;
  }
}
