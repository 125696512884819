


































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import WorkSession from '@/models/WorkSession';
import {jobStoreActions} from '@/stores/job.store';
import {namespace} from 'vuex-class';
import Image from '@/models/Image';

const JobStore = namespace('job');

interface Thumbnail {
  id: string;
  loaded: boolean;
  url: string;
}

interface Img {
  blob: Blob;
}

@Component({
  components: {
    RJTextField,
  },
})
export default class CommentTabComponent extends Vue {

  @JobStore.Action(jobStoreActions.LOAD_WORK_SESSION_IMAGE_ACTION)
  public loadWorkSessionImage!: (payload: {
    workSessionId: string,
    imageId: string,
    thumbnail: boolean,
  }) => Promise<Img>;

  @Prop()
  private selectedWorksession!: WorkSession;

  // Image section
  /**
   * array the images as thumbnail for faster loading times
   */
  public thumbnails: Thumbnail[] = [];

  /**
   * current Image in the Dialog
   */
  public selectedImage: Thumbnail | null = null;


  // for faster Image loading in the dialog

  public selectedImageKey: string = '';

  /**
   * Cache of full-loaded images
   */
  public imageCache: Thumbnail[] = [];

  public imageDialog: boolean = false;
  public readonly THUMBNAIL_SIZE: number = 100;

  public mounted() {
    this.loadThumbNails();
  }

  @Watch('selectedWorksession')
  public onWorkSessionChange() {
    this.loadThumbNails();
  }

  public async loadThumbNails() {
    this.thumbnails =
      await Promise.all(((this.selectedWorksession.images ?? []) as Image[]).map(async (image: Image): Promise<Thumbnail> => {
        try {
          const apiImage = await this.loadWorkSessionImage(
            {workSessionId: this.selectedWorksession.id, imageId: image.id, thumbnail: true});
          const fileObject = URL.createObjectURL(apiImage.blob);

          return {
            id: image.id,
            loaded: true,
            url: fileObject,
          };
        } catch (e: any) {
          return {
            id: '',
            loaded: false,
            url: '',
          };
        }
      }));

    this.$forceUpdate();
  }

  public async openSelectedImage(image: Thumbnail) {
    this.selectedImageKey = image.id!;
    const apiImage = await this.loadWorkSessionImage(
      {workSessionId: this.selectedWorksession.id, imageId: image.id!, thumbnail: false});
    const fileObject = URL.createObjectURL(apiImage.blob);

    this.selectedImage = {
      id: image.id,
      loaded: true,
      url: fileObject,

    };
    this.imageCache[this.selectedImageKey] = this.selectedImage;
    this.imageDialog = true;
  }

  private get maxImageIndex(): number {
    return this.thumbnails.length;
  }

  private isThumbnailAtPosition(position: number) {
    return this.thumbnails.findIndex((image) => this.selectedImageKey === image.id) === position;
  }

  private changeImageButtonClick(isRight: boolean) {
    let index = this.thumbnails.findIndex((image) => image.id === this.selectedImageKey);
    isRight ? index++ : index--;
    if (index in this.imageCache) {
      this.selectedImage = this.imageCache[index];
    } else {
      this.openSelectedImage(this.thumbnails[index]);
    }
  }
}
