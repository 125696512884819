/**
 * Manages the filter data for job api requests. The object properties do not container the complete objects. They
 * contain the id or isoString representation only.
 */
export default class JobsFilterData {

    /**
     * Main filter values with causes a reload after change
     */
    public company!: string;
    public dates: string[] = [];
    public users: string[] = [];
    public managers: string[] = [];
    public customer?: string;
    public locations: string[] = [];
    public includeSubstitutes?: boolean;

    constructor(obj?: Partial<JobsFilterData>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    /**
     * Returns the http request query parameter for main filter values
     */
    public get queryData(): Array<{ date: string, query: string }> {
        /**
         * ?date=2019-11-05T15:32:07.854Z
         * &customer=5dc1494b6d52bd15b035d90a
         * &location=5dc1494b6d52bd15b035d90c&location=5dc1494b6d52bd15b035d90d
         * &user=5dc1494a6d52bd15b035d904&user=5dc1494a6d52bd15b035d905
         * &manager=615db3e8a570e00894e0601e
         */
        return this.dates.map((date) => {
            const locations = this.locations.length > 0 ? this.locations.map((location) => `&location=${location}`).join('') : '';
            const customer = this.customer ? '&customer=' + this.customer : '';
            const users = this.users.length > 0 ? this.users.map((user) => `&user=${user}`).join('') : '';
            const managers = this.managers.length > 0 ? this.managers.map((manager) => `&manager=${manager}`).join('') : '';
            const substitute = this.includeSubstitutes ? '&substitutes=true' : '';

            return {
                date,
                query: `date=${date}&company=${this.company}${locations}${managers}${customer}${users}${substitute}`,
            };
        });
    }
}
