






































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import Throttle from '@/helper/Throttle';
import UserTimeTrackingComponent from '@/components/user/UserTimeTracking.component.vue';
import TimeTrackingFilterComponent from '@/components/time-tracking/TimeTrackingFilter.component.vue';
import UserBaseDataComponent from '@/components/user/UserBaseData.component.vue';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import moment, {Moment} from 'moment';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import UserArchiveComponent from '@/components/user/UserArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {TabItem} from '@/interfaces/TabItem';
import {Permission} from '@/misc/enums/permission.enum';
import JobCalendarComponent from '@/components/job/JobCalendar.component.vue';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import JobDetailSidebarContentComponent from '@/components/job/JobDetailSidebarContent.component.vue';
import JobsFilterData from '@/misc/JobsFilterData';
import {jobStoreActions} from '@/stores/job.store';
import CleanTime from '@/models/CleanTime';
import {WEEKDAYS} from '@/Constants';
import JobManageComponent from '@/components/job/JobManage.component.vue';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const JobStore = namespace('job');
const CleanTimeStore = namespace('cleanTime');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    RJTextField,
    RJTabs,
    UserArchiveComponent,
    UserTimeTrackingComponent,
    TimeTrackingFilterComponent,
    UserBaseDataComponent,
    JobCalendarComponent,
    SideCardComponent,
    JobDetailSidebarContentComponent,
    UserTimeSheetComponent: () => import(
        '@/components/user/UserTimeSheet.component.vue'),
    UserManageComponent: () => import(
        /* webpackChunkName: "UserManageComponent" */
        '@/components/user/UserManage.component.vue'),
    UploadDataComponent: () => import(
        '@/components/shared/UploadFile.component.vue'),
    JobManageComponent: () => import(
        /* webpackChunkName: "JobManage" */
        '@/components/job/JobManage.component.vue'),
    SubstituteComponent: () => import(
        '@/components/user/Substitute.component.vue'),
  },
})
export default class UserDetailsView extends Vue {

  @UserStore.Action('loadUserAction')
  private loadUserAction!: (userId: string) => Promise<User>;
  @UserStore.Action('resendInvitationAction')
  private resendInvitationAction!: (user: User) => Promise<void>;
  @UserStore.Getter('activeUser')
  private _user!: User;
  @UserStore.Mutation('storeActiveUser')
  private storeActiveUser!: (user: User | null) => void;
  @UserStore.Mutation('storeActiveUser')
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action('deleteUserAction')
  public deleteUserAction!: (user: User) => Promise<User>;
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (companyId: string) => Promise<Customer>;
  public companyId!: string;
  @UserStore.Action('editUserAction')
  private editUserAction!: (user: User) => Promise<User>;
  @JobStore.Action(jobStoreActions.LOAD_JOBS_ACTION)
  private loadJobsAction!: (payload: { filterData: JobsFilterData, discardCache?: boolean }) => Promise<Job[]>;
  @CleanTimeStore.Action('deleteCleanTimeAction')
  public deleteCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;
  @CleanTimeStore.Action('getCleanTimeAction')
  public getCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;

  get user(): User {
    return this._user;
  }

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  private currentJobInfo: {
    job: Job,
    userData: JobUserDataInterface[],
  } | null = null;

  /**
   * if true the job is not editable or deletable
   */
  private currentJobIsOver = true;

  /**
   *  Defines the inital type of the calendar
   */
  public calendarType: 'week' | 'day' | null = null;

  public autoInsert: boolean = false;

  /**
   * visual values
   */
  private throttle!: Throttle;
  private THROTTLE_DELAY: number = 500;
  public tabsModel: number = 0;
  public showManageUserSideCard = false;
  public showDeleteUserDialog = false;
  private showOTPDialog: boolean = false;

  private showSideCard: boolean = false;
  private openDeleteDialog = false;
  public resetJobDialog: boolean = false;

  public addJobDialog: boolean = false;
  public location: Location = new Location();

  /**
   * filter values
   */
  private dateStartPickerValue: string = '';
  private dateEndPickerValue: string = '';
  private filterHasComment: boolean = false;
  private filterHasImages: boolean = false;
  private customers: Customer[] | null = [];
  private currentFilter!: WorkSessionFilterData;
  public currentTimeFilter: string = 'month';
  // string for the OTP generation
  public oneTimePassword: string = '';
  /**
   * Current Date selected date, default is today's date.
   */
  public currentDate!: Moment;
  /**
   * Current Week, default is this week of the year
   */
  public currentWeek: number = 0;
  /**
   * Current year, default this year
   */
  public currentYear: number = 0;
  /**
   * Current Month, default is the this month of the year
   */
  public currentMonth: number = 0;
  /**
   * Max weeks of current year
   */
  public maxWeeks!: number;
  /**
   * All available Customer Locations
   */
  public customerLocations: Location[] = [];
  /**
   * Current Selected Locations
   * in the filter settings
   */
  public currentObjects: Location[] | null = [];

  public async mounted() {
    this.companyId = this.$route.params.companyId;
    try {
      // reset activeUser store entry to avoid wrong user loading bug
      this.storeActiveUser(null);
      // load all data that is required for this view
      await this.loadUserAction(this.$route.params.userId);
      this.throttle = new Throttle(this.THROTTLE_DELAY);
      await this.loadCustomersAction(this.companyId);
      await this.reloadCalender();
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }

    this.currentFilter = {
      company: this.companyId,
      endTimeAtFrom: moment().startOf('month').toISOString(),
      endTimeAtTo: moment().endOf('month').toISOString(),
      users: [this.user.id!],
    };
    // Load all necessary relations like customers, users and object locations
  }

  public async onDeleteCleanTime(cleanTimeId: string): Promise<void> {
    try {
      // Send API Request to delete the cleantime with the given id
      const deleted = await this.deleteCleanTimeAction(cleanTimeId);

      // If API send back a cleanTime Document is probably deleted
      if (deleted) {
        // try to find the given cleanTime in our Location cleantimes array
        const indexToDelete = this.location.cleanTimes.findIndex((cleanTime) => cleanTime.id === cleanTimeId);

        // If found delete from array
        if (indexToDelete > -1) {
          this.location.cleanTimes.splice(indexToDelete, 1);
        }

        // Show Success message
        this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.SUCCESS');

        // Clear Calendar Cache on Remove
        this.clearCachedJobs();

        // Close Delete Dialog
        this.openDeleteDialog = false;
        this.addJobDialog = false;
        await this.reloadCalender();

      }
      // catch all errors
    } catch (e) {
      // job was already deleted (by another user)
      if (e.status === 409) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.ALREADY_DELETED');
      } else {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.GENERAL');
      }
    }
  }

  private createPassword() {
    this.showOTPDialog = true;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'basedata',
        text: this.$t('USER_DETAIL.TABS.BASE_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      }, {
        key: 'timetracking',
        text: this.$t('GENERAL.TIME_TRACKING').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      }, /*{
        key: 'substitute',
        text: this.$t('USER_DETAIL.TABS.SUBSTITUTE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.CLEANTIME_UPDATE),
      },*/ {
        key: 'timesheet',
        text: this.$t('USER_DETAIL.TABS.TIMESHEET').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
      {
        key: 'documents',
        text: this.$t('USER_DETAIL.TABS.DOCUMENTS').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.FILE_READ_OWN),
      },
      {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
      {
        key: 'userCalendar',
        text: this.$t('GENERAL.JOBS').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.CLEANTIME_READ),
      },
    ];
  }

  /**
   * Resend the invitation link
   */
  public async resendInvitation() {
    this.throttle.throttle(async () => {
      try {
        await this.resendInvitationAction(this.user!);
        this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.SUCCESS');
      } catch (e: any) {
        if (e.status === 429) { // too error code for many mails were sent
          e.data.resendAllowedAt = this.$options.filters!.toDateTime(e.data.resendAllowedAt); // format date
          this.$notifyErrorSimplified(`USER_DETAIL.NOTIFICATIONS.INVITATION_RESEND.${e.status}`, e.data, 10000);
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    });
  }

  /**
   * create a new OTP for a user without an accepted invite
   */
  public async replacePassword() {
    this.oneTimePassword = Math.random().toString(36).substr(2, 8);
    try {
      const userCopy = User.parseFromObject(this.user.parseToObject());
      userCopy.oneTimePassword = this.oneTimePassword;
      // delete cleanTimes. They should not be updated. They cause an error anyway if filled with values
      delete userCopy.plannedCleanTimes;
      delete userCopy.availableAtCleanTimes;
      await this.editUserAction(userCopy);
      this.showOTPDialog = false;
      this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.OTP_ERROR');
    }
  }

  public onEditUserClick() {
    this.showManageUserSideCard = true;
  }

  public onDeleteUserClick() {
    this.showDeleteUserDialog = true;
  }

  public async onUserDelete() {
    this.showDeleteUserDialog = false;
    try {
      await this.deleteUserAction(this.user);
      this.$notifySuccessSimplified('USER.NOTIFICATIONS.USER_DELETE.SUCCESS');
      // if successful
      await this.$router.push({
        name: 'usersOverview', params: {
          companyId: this.$route.params.companyId,
        },
      });
    } catch (e: any) {
      this.$notifyErrorSimplified('USER.NOTIFICATIONS.USER_DELETE.ERROR');
    }
  }

  private getTabSize(): number {
    if (this.tabsModel === 1) {
      return 10;
    } else {
      return 12;
    }
  }

  private onTimeFilterUpdated(timeFilter: string) {
    this.currentTimeFilter = timeFilter;

    // Reset Values if TimeFilter was changed
    const today = moment();
    this.currentDate = today;
    this.currentYear = today.year();
    this.maxWeeks = today.isoWeeksInYear();
    this.currentWeek = today.week();
    this.currentMonth = today.month() + 1;
  }

  public getCurrentFilterSettings(): WorkSessionFilterData {

    // Build Filter Object
    const filter = {...this.currentFilter};

    // Set Customers to Filter
    filter.customers = this.customers && this.customers.length > 0
        ? this.customers!.map((customer) => customer.id!)
        : null;

    // Set Locations to Filter
    filter.locations = this.currentObjects && this.currentObjects.length > 0
        ? this.currentObjects!.map((location) => location.id!)
        : null;

    // individual time frame
    if (this.isIndividual) {
      const fromDate = moment.utc(this.dateStartPickerValue);
      filter.endTimeAtFrom = fromDate.startOf('day').toISOString();

      // Check if End Date was Set
      // if not use today's date
      const toDate = this.dateEndPickerValue.length > 0
          ? moment.utc(this.dateEndPickerValue)
          : moment();

      // Set Created To Date
      filter.endTimeAtTo = toDate.endOf('day').local().toISOString();
    }

    // Selected day as Timeframe
    if (this.isDaily) {
      const startOfDay = moment(this.currentDate).startOf('day');
      const endOfDay = moment(this.currentDate).endOf('day');
      filter.endTimeAtFrom = startOfDay.toISOString();
      filter.endTimeAtTo = endOfDay.toISOString();
    }

    // Selected Month as Timeframe
    if (this.isMonthly) {
      // Get Current Week
      const month = moment().set('month', this.currentMonth - 1).set('year', this.currentYear);

      // Get start and end of Week
      const startOfMonth = moment(month).startOf('month');
      const endOfMonth = moment(month).endOf('month');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfMonth.toISOString();
      filter.endTimeAtTo = endOfMonth.toISOString();
    }

    // Selected Week as Timeframe
    if (this.isWeekly) {
      // Get Current Week
      const week = moment().set('year', this.currentYear).set('week', this.currentWeek);

      // Get start and end of Week
      const startOfWeek = moment(week).startOf('week');
      const endOfWeek = moment(week).endOf('week');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfWeek.toISOString();
      filter.endTimeAtTo = endOfWeek.toISOString();
    }

    filter.hasComments = this.filterHasComment;
    filter.hasImages = this.filterHasImages;

    return filter;
  }

  private filterData: JobsFilterData = new JobsFilterData();

  // TODO after new design: implement more efficient reload
  public async reloadCalender() {
    for (let i = 0; i < 7; i++) {
      const date = moment().startOf('week').add(i, 'days');
      this.filterData.dates.push(date.toISOString());
    }
    this.filterData.users = (this.user.id as string).split(',');
    this.filterData.company = this.$route.params.companyId as string;
    await this.loadJobsAction({filterData: this.filterData, discardCache: true});
  }

  /**
   * Is called if main filter data changes. Loads new data and discards job cache to get job data that
   * respects filter data
   */
  public loadJobs(data: JobsFilterData) {
    // merge filter data
    Object.assign(this.filterData, data);
    this.throttle.throttle(async () => {
      try {
        await this.loadJobsAction({filterData: this.filterData, discardCache: true});
      } catch (e) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    });
  }

  /**
   * Listener for Date Value Changes
   * sets the end and start picker values
   * e.g. for displaying at the top
   */
  public onDateValuesChanged(dates: { dateStart: string, dateEnd: string }) {
    this.dateEndPickerValue = dates.dateEnd;
    this.dateStartPickerValue = dates.dateStart;
  }

  /**
   * Event Listener for Misc Boolean changes
   */
  private onMiscUpdated(data: { hasComments: boolean, hasImages: boolean }) {
    this.filterHasImages = data.hasImages;
    this.filterHasComment = data.hasComments;
  }

  public async onUseCleanTime(cleanTime: CleanTime, edit = true, useOrigin = false): Promise<void> {
    try {
      // get CleanTime from api
      const apiCleanTime = await this.getCleanTimeAction(useOrigin ? cleanTime.origin : cleanTime.id!);
      apiCleanTime.recurrence = this.getRecurrenceFromByWeek(apiCleanTime);

      // if by weekday is null, every day was selected
      if (apiCleanTime.byWeekDay === null) {
        apiCleanTime.byWeekDay = WEEKDAYS.map((val: string) => val);
      }

      // opens the dialog box
      this.showJobDialog(edit, apiCleanTime, true);
    } catch (e) {
      if (e.status === 404) {
        // Shows an error message that the cleanTime is already deleted and cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.CLEANTIME_ALREADY_DELETED');
      } else {
        // Shows a generic error message that the cleanTime cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.COULD_NOT_EDIT_CLEANTIME');
      }
    }
  }

  public showJobDialog(editMode: boolean = false, apiCleanTime?: CleanTime, insertAttributes = false): void {
    this.autoInsert = false;

    // Reset Job Dialog and show it in the end.
    this.resetJobDialog = true;
    this.addJobDialog = true;

    this.$nextTick(() => {
      this.resetJobDialog = false;

      // if job dialog should be opened in edit mode
      // Wait a dom update to tell the job manage component to do so
      if (insertAttributes) {
        this.autoInsert = true;

        // waits a dom update and inserts the existing cleanTime data to the form
        this.$nextTick(() => {
          // Call the Edit method for the cleantime
          (this.$refs.jobManageComponent as JobManageComponent).setEditCleanTime(apiCleanTime!, editMode);
          this.showSideCard = false;
        });
      }
    });
  }

  public getRecurrenceFromByWeek(cleanTime: CleanTime): string {
    let recurrence = '1';

    // Check if the frequency is not daily
    // and if the byWeek Array has a length greater then zero
    if (cleanTime!.freq! !== 'daily' && cleanTime!.byWeekDay!.length > 0) {

      // Get first byWeekDay entry
      const day = cleanTime.byWeekDay![0];

      // Tr to find any numbers in our selected day
      const match = day!.match('[\\d -]+');

      // If match was found and has a length greater than zero, take the first entry
      // Else use 1
      recurrence = (match && match!.length > 0) ? match![0] : '1';
    }
    // returns the found recurrence
    return recurrence;
  }

  /**
   * Gets executed if the clear button
   * for a customer was pressed
   */
  public onCustomerClearClicked() {
    // Reset locations and current selected object
    this.customers = [];
    this.customerLocations = [];
    this.currentObjects = [];
  }

  public onObjectChanged(objects: Location[]) {
    this.currentObjects = objects;
  }

  public async onCustomerSelected(customers: Customer[]) {
    // Set Customers
    this.customers = customers;

    // Reset locations
    this.customerLocations = [];

    // Check if customer has data, if not return
    if (!this.customers || this.customers!.length <= 0) {
      return;
    }

    // Go through all selected customers, gather all location promises
    const customerPromises: Array<Promise<Customer>>
        = this.customers.map((element) => this.loadCustomerAction(element.id!));

    // Await all Customer Promises
    const resolvedCustomers: Customer[] = await Promise.all(customerPromises);

    // set current loaded locations
    this.customerLocations = ([] as Location[]).concat(...resolvedCustomers.map((customer: Customer) =>
        customer.locations));
  }

  /**
   * Checks if Filter is set to Individual
   */
  private get isIndividual(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'individual';
  }

  /**
   * Checks if Filter is set to Weekly
   */
  private get isWeekly(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'week';
  }

  /**
   * Checks if Filter is set to Daily
   */
  private get isDaily(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'day';
  }

  /**
   * Checks if Filter is set to Monthly
   */
  private get isMonthly(): boolean {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'month';
  }

  public onJobInfoChange(value: {
    job: Job,
    userData: JobUserDataInterface[],
  }) {
    this.currentJobInfo = value;
    this.currentJobIsOver = moment().isAfter(moment(value.job.cleanTimeOccurrence.end));
    this.showSideCard = true;
    this.hideJobDialog();
  }

  /**
   * Event handler for calendar type change
   */
  public onCalendarTypeChange(value: 'week' | 'day') {
    this.calendarType = value;
  }

  /**
   * Hides the Job Dialog, if abort was pressed
   */
  public hideJobDialog(): void {
    this.addJobDialog = false;
  }

  public onWorkSessionUpdate() {
    this.reloadCalender();
    this.showSideCard = false;
  }

}
