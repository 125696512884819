








































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import {namespace} from 'vuex-class';
import WorkSession from '@/models/WorkSession';
import moment, {Moment} from 'moment';
import {convertMs} from '@/helper/JobTimeHelper';
import User from '@/models/User';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import Location from '@/models/Location';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import TimeTrackingSidebarContentComponent from '@/components/time-tracking/TimeTrackingSidebarContent.component.vue';
import PaginationComponent from '@/components/shared/table/Pagination.component.vue';
import {jobStoreActions} from '@/stores/job.store';


const JobStore = namespace('job');
const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const CompanyStore = namespace('company');


@Component({
  components: {
    SideCardComponent,
    TimeTrackingSidebarContentComponent,
    PaginationComponent,
  },
})
export default class UserTimeTrackingComponent extends Vue {

  @UserStore.Getter('activeUser')
  private _user!: User;
  @JobStore.Action(jobStoreActions.GET_WORK_SESSIONS_ACTION)
  private getWorkSessionsAction!: (filterSettings: WorkSessionFilterData) => Promise<WorkSession[]>;
  @UserStore.Getter('users')
  private _users!: User[];

  get user(): User {
    return this._user;
  }

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  get customers(): Customer[] {
    return this._customers;
  }

  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string }) => Promise<User[]>;
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (companyId: string) => Promise<Customer>;
  @CustomerStore.Action('loadLocationAction')
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @JobStore.Action('loadWorkSessionAction')
  private loadWorkSessionAction!: (workSessionId: string) => Promise<WorkSession>;

  //region API Arrays and Data-Table specific things
  /**
   * current Page of the the Worksession-table
   */
  private page: number = 1;
  /**
   *  items Per page on the Worksession-table
   */
  private perPage: number = 25;
  /**
   * Current Date selected date, default is today's date.
   */
  private currentDate!: Moment;
  /**
   * Current Week, default is the this week of the year
   */
  private currentWeek: number = 0;
  /**
   * Current year, default this year
   */
  private currentYear: number = 0;
  /**
   * Current Month, default is the this month of the year
   */
  private currentMonth: number = 0;
  /**
   * Max weeks of current year
   */
  private maxWeeks!: number;
  /**
   * Max Months of year year
   */
  private maxMonths: number = 12;
  /**
   * All available Customer Locations
   */
  @Prop({default: () => []})
  private customerLocations!: Location[];
  /**
   * Loaded Worksessions
   */
  private worksessions: WorkSession[] = [];
  /**
   * sets the worksession SideCard active or not
   */
  private showSideCard = false;
  /**
   * Currently selected Worksession
   * will be set during a click on a table row
   */
  private selectedWorksession: WorkSession | null = null;
  //endregion

  //region Selected Filter Values
  /**
   * Current Filter Settings
   * Default is the company and the user
   */
  @Prop()
  private currentFilter!: WorkSessionFilterData;
  /**
   * Current Selected TimeFrame Filter
   * default is month
   */
  @Prop({default: 'month'})
  private currentTimeFilter!: string;
  //endregion

  //region Date Picker Values
  /**
   * Internal date start string (necessary for picker use)
   */
  @Prop({default: ''})
  private dateStartPickerValue!: string;
  /**
   * Internal date end string (necessary for picker use)
   */
  @Prop({default: ''})
  private dateEndPickerValue!: string;

  /**
   * is true if the Worksession are loaded
   */
  private isLoading: boolean = false;

  //endregion


  public created() {
    const today = moment();

    // Get and set current days date.

    this.currentDate = today;

    // Get current week
    this.currentWeek = today.isoWeek();
    this.maxWeeks = today.weeksInYear();

    // get current month
    this.currentMonth = today.month() + 1;

    // Get current Year
    this.currentYear = today.year();
  }

  public async mounted() {
    try {
      // Resolve all promises
      const worksessions = await this.getWorkSessionsAction(this.getCurrentFilterSettings());

      // Set Worksessions for this call
      this.worksessions = worksessions.filter((workSession) => workSession.duration != null);

    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * Checks if start and end date
   * are selected
   */
  public get isTimeframeSelected() {
    return this.dateStartPickerValue.length > 0 && this.dateEndPickerValue.length > 0;
  }

  /**
   * updates the Items per Page on the Table
   */
  private changePerPage(count: number) {
    this.perPage = count;
  }

  /**
   * filter the Table-items according to the items per Page
   */
  private get tableItems(): WorkSession[] {
    return this.worksessions.filter((item: any, index: number) => index >= (this.page - 1) * this.perPage && index < (this.page) * this.perPage);
  }

  /**
   * Checks if Filter is set to Individual
   */
  public get isIndividual(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'individual';
  }

  /**
   * Checks if Filter is set to Weekly
   */
  public get isWeekly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'week';
  }

  /**
   * Checks if Filter is set to Daily
   */
  public get isDaily(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'day';
  }

  /**
   * Checks if Filter is set to Monthly
   */
  public get isMonthly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'month';
  }

  /**
   * Checks if starttime is selected
   */
  public get hasStartTime() {
    return this.dateStartPickerValue.length > 0;
  }

  @Watch('currentFilter')
  public onCurrentFilterChanged() {
    if (this.currentFilter.endTimeAtFrom !== null) {
      this.onFilterChanged();
    }
  }

  public onPreviousClicked() {
    // is Daily update Date, by subtracting one day
    if (this.isDaily) {
      this.currentDate = this.currentDate.subtract(1, 'day');
    }

    // is Monthly update Date, by subtracting one month
    if (this.isMonthly) {
      const subtractValue = this.currentMonth - 1;

      if (subtractValue < 1) {
        // Decrease Year
        this.currentYear--;

        // Set Month to December
        this.currentMonth = 12;
      } else {
        this.currentMonth = subtractValue;
      }
    }

    // is Weekly update week count by subtracting one
    if (this.isWeekly) {
      const subtractValue = this.currentWeek - 1;

      // If smaller than one, change year to previous
      // and change week to max week of this year
      if (subtractValue < 1) {
        // Decrease Year
        this.currentYear--;

        // Get Week Count of specific Year
        const weeksInYear = moment().set('year', this.currentYear);

        // Set Week Value for this year
        this.maxWeeks = weeksInYear.isoWeeksInYear();
        this.currentWeek = weeksInYear.isoWeeksInYear();
      } else {
        this.currentWeek = subtractValue;
      }
    }

    // Execute Filter Changes
    this.onFilterChanged();
  }

  public onNextClicked() {
    // is Daily update Date, by adding one day
    if (this.isDaily) {
      this.currentDate = this.currentDate.add(1, 'day');
    }

    // is Weekly update week count by adding one
    // and checking if its above the max week count
    // if the value is above, set to max weeks
    if (this.isWeekly) {
      const addValue = this.currentWeek + 1;

      // Check if Above Max Weeks
      // Then switch to next year
      if (addValue > this.maxWeeks) {
        // Increase Year
        this.currentYear++;

        // Get Week Count of specific Year
        const weeksInYear = moment().set('year', this.currentYear);

        // Set Week Value for this year
        this.maxWeeks = weeksInYear.isoWeeksInYear();
        this.currentWeek = 1;
      } else {
        this.currentWeek = addValue;
      }
    }

    // is Month update by adding one all the way to 12
    if (this.isMonthly) {
      const addValue = this.currentMonth + 1;

      // Check if above December
      if (addValue > this.maxMonths) {
        // Increase Year
        this.currentYear++;

        // Set Month to January
        this.currentMonth = 1;
      } else {
        this.currentMonth = addValue;
      }
    }

    // Execute Filter Changes
    this.onFilterChanged();
  }

  public getCurrentFilterSettings(): WorkSessionFilterData {

    // Build Filter Object
    const filter = {...this.currentFilter};

    filter.sort = 'createdAt+DESC';

    if (this.isIndividual) {
      const fromDate = moment.utc(this.dateStartPickerValue);
      filter.endTimeAtFrom = fromDate.startOf('day').toISOString();

      // Check if End Date was Set
      // if not use today's date
      const toDate = this.dateEndPickerValue.length > 0
        ? moment.utc(this.dateEndPickerValue)
        : moment();

      // Set Created To Date
      filter.endTimeAtTo = toDate.endOf('day').local().toISOString();
    }

    // Selected day as Timeframe
    if (this.isDaily) {
      const startOfDay = moment(this.currentDate).startOf('day');
      const endOfDay = moment(this.currentDate).endOf('day');
      filter.endTimeAtFrom = startOfDay.toISOString();
      filter.endTimeAtTo = endOfDay.toISOString();
    }

    // Selected Month as Timeframe
    if (this.isMonthly) {
      // Get Current Week
      const month = moment().set('month', this.currentMonth - 1).set('year', this.currentYear);

      // Get start and end of Week
      const startOfMonth = moment(month).startOf('month');
      const endOfMonth = moment(month).endOf('month');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfMonth.toISOString();
      filter.endTimeAtTo = endOfMonth.toISOString();
    }

    // Selected Week as Timeframe
    if (this.isWeekly) {
      // Get Current Week
      const week = moment().set('year', this.currentYear).set('week', this.currentWeek);

      // Get start and end of Week
      const startOfWeek = moment(week).startOf('week');
      const endOfWeek = moment(week).endOf('week');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfWeek.toISOString();
      filter.endTimeAtTo = endOfWeek.toISOString();
    }
    return filter;
  }

  /**
   * Executes Filter Changes and reloads data
   */
  private async onFilterChanged() {

    this.isLoading = true;

    // Load worksessions with edited filter
    const unfilteredSessions = await this.getWorkSessionsAction(this.getCurrentFilterSettings());

    // Filter Sessions by duration
    this.worksessions = unfilteredSessions.filter((worksSession) => worksSession.duration != null);

    this.isLoading = false;
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('TIMETRACKING.HEADER.CUSTOMER'), class: 'table-header--text', value: 'customer', width: '18vw'},
      {
        text: this.$t('TIMETRACKING.HEADER.CLEANING_OBJECT'),
        class: 'table-header--text',
        value: 'cleaning_object',
        width: '12vw',
      },
      {text: this.$t('TIMETRACKING.HEADER.DATE'), class: 'table-header--text', value: 'date', width: '12vw'},
      {text: this.$t('TIMETRACKING.HEADER.TIME'), class: 'table-header--text', value: 'time', width: '10vw'},
      {text: this.$t('TIMETRACKING.HEADER.TARGETTIME'), class: 'table-header--text', value: 'target-time', width: '10vw'},
      {text: this.$t('TIMETRACKING.HEADER.COMMENTS'), class: 'table-header--text', value: 'comments'},
    ];
  }

  private get footerProps(): any {
    return {'items-per-page-options': [10, 25, 50, 100]};
  }

  public get locale(): string {
    return this.$i18n.locale;
  }

  public getCustomerName(customerId: string) {
    return this.customers.find((item) => item.id === customerId)!.name;
  }

  public hasDuration(item: WorkSession): boolean {
    return item.durationHumanized![this.locale].length >= 0 && !item.canceledByReason;
  }

  public hasDurationTracked(item: WorkSession): boolean {
    return item.durationTrackedHumanized![this.locale].length >= 0 && !item.canceledByReason;
  }

  public isWorkSessionAborted(item: WorkSession) {
    return !!item.canceledByReason;
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()}:${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS.ABBRV.HOUR')}`;
  }

  /**
   * Returns the current status of a worksession
   * e.g if aborted shows Abort Message
   */
  public getWorkSessionRealTimeStatus(item: WorkSession): string {
    if (this.isWorkSessionAborted(item)) {
      return this.$t('JOB_OVERVIEW.JOB_CANCELLED').toString();
    }

    return this.$t('JOB_OVERVIEW.STILL_RUNNING').toString();
  }

  public getFormattedTime(startDate: Date, endDate: Date) {
    // Get Moment Object from start and end
    const start = moment(startDate);
    const end = moment(endDate);

    // Date is not valid, the job wasnt worked on
    if (!start.isValid() || !end.isValid()) {
      return this.$t('TIMETRACKING.MESSAGES.NOT_DETERMINED').toString();
    }

    // Return Time representation
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }

  public getDurationDeltaString(duration: number, durationTracked: number) {
    // Calculate duration delta
    const delta = Math.abs(duration - durationTracked);

    // convert delta to a neat time object
    const time = convertMs(delta);

    // pad absolute hours and minutes at start to have two numbers at all times
    const hours = (time.hour + (time.day * 24)).toString();
    const minutes = time.minute.toString().padStart(2, '0');

    // check if its negative or positive.
    let prefixString = '';
    if (durationTracked < duration) {
      prefixString = '-';
    } else if (durationTracked > duration) {
      prefixString = '+';
    }

    // return formatted string
    const abbr = this.$t('GENERAL.UNITS.ABBRV.HOUR').toString();
    return `${prefixString}${hours}:${minutes} ${abbr}`;
  }

  public hasImages(item: WorkSession): boolean {
    return item.hasImages!;
  }

  public hasComment(item: WorkSession): boolean {
    return item.hasComments!;
  }

  public async onRowClicked(workSession: WorkSession) {
    // load whole passed object and set as Selected Worksession
    await this.loadWorkSessionAction(workSession.id).then((value) => {
      this.selectedWorksession = value;
    });
    // Show Side Card
    this.showSideCard = true;
  }

  private wasEdited(workSession: WorkSession) {
    if (!workSession.durationTracked || workSession.vcOrigin) {
      return true;
    }

    // duration has a puffer of one minute to not be counted as edited
    const duration = moment(workSession.endTime).diff(moment(workSession.durationSetAt));
    return Math.abs(duration) > 1000 * 60;
  }

}
