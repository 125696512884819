




































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import User from '@/models/User';
import moment from 'moment';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import {decimal, maxValue, minLength, minValue, required, requiredIf} from 'vuelidate/lib/validators';

const checkTime = (value: string) => {
  // The length of the time string should not be longer than 5(eg: 00:00) and contain not more than 2 separators
  if (value.length > 5) {
    return false;
  }
  const parts = value.split(':');
  if (parts.length === 2) {
    // The first part should not have more than 2 digits and needs to be greater than 0 and less than 23(24h format)
    if (!(parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23)) {
      return false;
    }
    // The second part should not have more than 2 digits and needs to be greater than 0 and less than 60(minutes)
    return parts[1].length <= 2 && +parts[1] >= 0 && +parts[1] < 60;
  }
  // If the string has no separator, it's the hours
  return parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23;
};

@Component({
  mixins: [validationMixin],
  validations: {
    dateStartPickerValue: {
      required,
    },
    timeEndPickerValue: {
      required,
      isTimeFormat: checkTime,
    },
    timeStartPickerValue: {
      required,
      isTimeFormat: checkTime,
    },
    dayPicked: {
      required: requiredIf((vm) => {
        return vm.cleanTime.freq !== 'daily';
      }),
    },
    cleanTime: {
      date: {
        required,
      },
      times: {
        required,
        minLength: minLength(1),
      },
      freq: {
        required,
      },
      interval: {
        required,
        decimal,
        maxValue: maxValue(12),
        minValue: (value, vm) => {
          // Check if the current selected mode is weekly and
          // if our interval is below 2, then return with a min value of 2
          // else return with a min value of 1
          if (vm.freq === 'weekly' && vm.interval < 2) {
            return minValue(2)(value);
          }
          return minValue(1)(value);
        },
      },
      recurrence: {
        required: requiredIf((vm) => {
          return vm.freq === 'monthly';
        }),
      },
      byWeekDay: {
        required,
        minLength: minLength(1),
      },
    },
  },
  components: {
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    RJChip: () => import(
        '@/components/shared/custom-vuetify/RJChip.vue'),
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
  },
})
export default class TimeTrackingFilterComponent extends mixins(ErrorMessageHandlerMixin) {

  //region Props
  @Prop()
  private availableManagers!: User[];

  @Prop()
  private availableUsers!: User[];

  @Prop()
  private customers!: Customer[];

  @Prop()
  private customerLocations!: Location[];

  @Prop()
  private parentTimeFilter!: string;

  @Prop({default: true})
  private showUsers?: boolean;
  //endregion

  //region Model Values
  /**
   * Current Selected Employee in
   * the filter settings menu
   */
  public employees: User[] | null = [];

  /**
   * Current selected managers in
   * the filter settings menu
   */
  public managers: User[] | null = [];

  /**
   * Current Selected Customer in
   * the filter settings menu
   */
  private selectedCustomers: Customer[] | null = [];

  /**
   * Current Selected Location in
   * the filter settings menu
   */
  private currentObjects: Location[] | null = [];

  /**
   * Current Selected Time Frame Setting
   */
  public currentTimeFilter: string = 'week';

  public hasComments: boolean = false;
  public hasImages: boolean = false;
  public manual: boolean = false;

  //endregion

  //region Datepicker Variables
  public dateStartDisplayValue: string = '';
  /**
   * Internal date start string (necessary for picker use)
   */
  public dateStartPickerValue: string = '';
  /**
   * Humanized date end string
   */
  public dateEndDisplayValue: string = '';
  /**
   * Internal date end string (necessary for picker use)
   */
  public dateEndPickerValue: string = '';
  /**
   * Menu flag for start date menu
   */
  public startDateMenuFlag: boolean = false;
  /**
   * Menu flag for end date menu
   */
  public endDateMenuFlag: boolean = false;
  /**
   * Min max values for date start picker
   */
  public dateStartMinMax: { max: string } = {
    max: '',
  };
  /**
   * Min max values for date end picker
   */
  public dateEndMinMax: { min: string, max: string } = {
    min: '',
    max: '',
  };
  /**
   * Value of time start picker
   */
  public timeStartPickerValue: string = '';
  /**
   * Value of time end picker
   */
  public timeEndPickerValue: string = '';
  /**
   * Main model of this component
   */
  @Prop({default: () => new CleanTime()})
  public cleanTime!: CleanTime;
  /**
   * Max values for time start picker
   */
  public timeStartMax: string = '';
  /**
   * Min value for time end picker
   */
  public timeEndMin: string = '';
  /**
   *  Min distance of time between start and end time
   */
  public minTimeDistance: number = 5;
  /**
   * Bool to check if the same time was entered
   */
  public hasSameTime: boolean = false;
  //endregion

  /**
   * TimeFilter Array for holding all the
   * available filters
   */
  public timeFilters!: Array<{ text: string, value: string }>;

  public created() {
    // Initialize Filter Components
    this.initializeComponents();

    // Set Current Time Filter, the same as parent
    if (this.parentTimeFilter) {
      this.currentTimeFilter = this.parentTimeFilter;
    }
  }

  public onTimeFilterUpdated() {
    this.$emit('time-filter-updated', this.currentTimeFilter);

    // Send Filter Changed event if not individual
    if (!this.isIndividual) {
      // Reset Datepicker values
      this.resetDatePickers();

      // Emit Filter Changed Event
      this.$emit('filter-changed');
    }
  }

  /**
   * Resets Date picker Values
   * to be empty
   */
  private resetDatePickers() {
    this.dateStartPickerValue = '';
    this.dateEndPickerValue = '';
    this.dateEndDisplayValue = '';
    this.dateStartDisplayValue = '';
  }

  /**
   * Sets filter to today
   */
  public setToToday() {
    // Set Mode to Day
    this.currentTimeFilter = 'day';

    // Reset Date Picker Values
    this.resetDatePickers();
  }

  /**
   * Listens on TimeFilter changes
   * e.g today clicked
   */
  @Watch('parentTimeFilter')
  private onParentTimeFilterChanged() {
    // Check if time filter was set to day,
    // then reset everything and select day
    if (this.parentTimeFilter === 'day') {
      this.setToToday();
    }
  }

  public initializeComponents() {
    // Initialize filters with localized text and values
    this.timeFilters = [
      {text: this.$t('JOB_OVERVIEW.DAILY').toString(), value: 'day'},
      {text: this.$t('JOB_OVERVIEW.WEEKLY').toString(), value: 'week'},
      {text: this.$t('JOB_OVERVIEW.MONTHLY').toString(), value: 'month'},
      {text: this.$t('TIMETRACKING.FILTER.INDIVIDUAL_PERIOD').toString(), value: 'individual'},
    ];

    // Initialize min and max values for date pickers
    this.dateEndMinMax.min = moment().toISOString(true);
  }

  /**
   * Reloads Data
   */
  public onReloadClicked() {
    this.currentTimeFilter = 'week';
    this.onTimeFilterUpdated();
    this.employees = [];
    this.selectedCustomers = [];
    this.currentObjects = [];
    this.hasComments = false;
    // Reset Date Picker Values
    this.resetDatePickers();
    // Emit Filter Changed Event
    this.$emit('filter-changed');

    this.$emit('on-reload-clicked');
  }

  public onObjectChanged() {
    this.$emit('object-selected', this.currentObjects);

    // Emit Filter Changed Event
    this.$emit('filter-changed');
  }

  public onCustomerSelected() {
    this.$emit('customer-selected', this.selectedCustomers);

    // Emit Filter Changed Event
    this.$emit('filter-changed');
  }

  public onManagerSelected() {
    this.$emit('manager-selected', this.managers);
    // emit filter changed event
    this.$emit('filter-changed');
  }

  public onEmployeeSelected() {
    this.$emit('employees-selected', this.employees);
    // Emit Filter Changed Event
    this.$emit('filter-changed');
  }

  public get isIndividual() {
    return this.currentTimeFilter.length > 0
        && this.currentTimeFilter === 'individual';
  }

  /**
   * Event handler for on start date change. Sets the date start display value and real cleanTime.date value.
   */
  public onDateStartChange(date: string) {
    this.triggerValidation('dateStartPickerValue');
    this.checkForSameTime();

    // Parse Date to moment object
    const parsedDate = moment(date);

    // Set Display Value
    this.dateStartDisplayValue = parsedDate.format('DD.MM.YYYY');

    // Set End min Value
    this.dateEndMinMax.min = parsedDate.toISOString(true);

    // Set end max value
    this.dateEndMinMax.max = parsedDate.add(1, 'year').toISOString(true);
    this.emitDateUpdate();
    this.$emit('filter-changed');
  }

  /**
   * Event handler for on end date change. Sets the date end display value and real cleanTime.date value.
   */
  public onDateEndChange(date: string) {
    this.checkForSameTime();
    this.dateEndDisplayValue = moment(date).format('DD.MM.YYYY');
    this.dateStartMinMax.max = moment(date).toISOString(true);
    this.emitDateUpdate();
    this.$emit('filter-changed');
  }

  /**
   * Emits and Date-values-changed event
   * and sends the start and end date of a time frame
   */
  private emitDateUpdate() {
    const dateStart = this.dateStartPickerValue.length > 0
        ? this.dateStartPickerValue + '' : '';

    const dateEnd = this.dateEndPickerValue.length > 0
        ? this.dateEndPickerValue + '' : '';

    // Emit Date Changed Event
    this.$emit('date-values-changed', {
      dateStart,
      dateEnd,
    });
  }

  /**
   * remove given manager from the selected user array
   * @param item the user to remove
   */
  public onRemoveManager(item: User) {
    // Check if passed User is inside managers array
    const index = this.managers!.findIndex((element) => element.id === item.id);

    // Remove if manager found
    if (index > -1) {
      this.managers?.splice(index, 1);

      // Emit filter changed event
      this.$emit('filter-changed');
    }
  }

  /**
   * Remove Given Employee from selected user array
   */
  public onRemoveEmployee(item: User) {
    // Check if passed user is inside employees array
    const index = this.employees!.findIndex((element) => element.id === item.id);

    // Remove if Employee found
    if (index > -1) {
      this.employees?.splice(index, 1);

      // Emit Filter Changed event
      this.$emit('filter-changed');
    }
  }

  /**
   * Remove Given Customer from selected user array
   */
  public onRemoveCustomer(item: User) {
    const index = this.selectedCustomers!.findIndex((element) => element.id === item.id);

    // Remove User if found
    if (index > -1) {
      this.selectedCustomers?.splice(index, 1);

      // Emit Filter Changed event
      this.$emit('filter-changed');
    }
  }

  /**
   * Listener for HasImage and HasComment changes
   */
  public onFilterImageCommentUpdate() {
    // Send Misc Changed Event
    this.$emit('on-show-image-update', {
      hasComments: this.hasComments,
      hasImages: this.hasImages,
    });

    // Send Filter Changed event
    this.$emit('filter-changed');
  }

  /**
   * Listener for HasImage and HasComment changes
   */
  public onFilterManual() {
    // Send Misc Changed Event
    this.$emit('on-show-manual-update', {
      manual: this.manual,
    });

    // Send Filter Changed event
    this.$emit('filter-changed');
  }

  /**
   * Remove Given Location from selected Objects array
   */
  public onRemoveObject(item: Location) {
    // Search for index of passed location
    const index = this.currentObjects!.findIndex((element) => element.id === item.id);

    // Remove if Found
    if (index > -1) {
      this.currentObjects?.splice(index, 1);

      // Emit Filter Changed event
      this.$emit('filter-changed');
    }
  }

  /**
   * Checks if objects are available
   * if not returns false
   */
  public get isObjectAvailable() {
    return this.selectedCustomers == null || this.selectedCustomers.length <= 0 || !this.customerLocations
        || this.customerLocations.length <= 0;
  }

  /**
   * Event handler for start time picker change
   */
  public onTimeStartChange() {
    this.$emit('change');
    this.triggerValidation('timeStartPickerValue');
    this.checkForSameTime();
    if (this.timeEndPickerValue) {
      this.cleanTime.setTime(this.timeStartPickerValue, this.timeEndPickerValue);
      this.cleanTime.getEndTime();
    }

    // Checks if the start time and end time is the same, if so reset end time due to not having the min distance
    if (this.timeStartPickerValue === this.timeEndPickerValue) {
      this.timeEndPickerValue = '';
    }

    // calculates the minimum EndTime
    // splits the string into its hours and minutes part
    const splittedStartTime = this.timeStartPickerValue.split(':');
    let hours = parseFloat(splittedStartTime[0]);
    let minutes = parseFloat(splittedStartTime[1]);

    // Adds the min distance on top of the minutes
    minutes += this.minTimeDistance;

    // Checks if our minutes is over or 60 then adds up hours and substracts 60minutes
    if (minutes >= 60) {
      hours++;
      minutes -= 60;
    }

    // sets new minimum endTime
    this.timeEndMin = `${hours}:${minutes}`;
  }

  /**
   * Checks if the dates and the times are the same and shows an error message if so
   */
  public checkForSameTime() {
    if (this.timeStartPickerValue === '' || this.timeEndPickerValue === '' ||
        this.dateStartPickerValue === '' || this.dateEndPickerValue === '') {
      this.hasSameTime = false;
      return;
    }

    // set hasSameTime boolean according to if start and end have the same time
    this.hasSameTime = this.timeStartPickerValue === this.timeEndPickerValue
        && this.dateStartPickerValue === this.dateEndPickerValue;
  }

  @Watch('cleanTime', {deep: true, immediate: true})
  private input() {
    this.$emit('input', {
      cleanTime: this.cleanTime,
      vuelidate: this.$v,
    });
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  /**
   * Support function to get user name
   * @param user
   */
  public getUserFullName(user: User): string {
    return user.fullName;
  }
}
